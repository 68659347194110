import React, { useEffect, useRef } from 'react';
import '../Styles/mayser.css';

const MoyasarPaymentForm = ({ amount, onSubmit }) => {
  const formRef = useRef(null);

  useEffect(() => {
    // Load Moyasar script
    const moyasarScript = document.createElement('script');
    moyasarScript.src = 'https://cdn.moyasar.com/mpf/1.7.3/moyasar.js';
    moyasarScript.async = true;
    document.body.appendChild(moyasarScript);

    // Load Moyasar CSS
    const moyasarCss = document.createElement('link');
    moyasarCss.href = 'https://cdn.moyasar.com/mpf/1.7.3/moyasar.css';
    moyasarCss.rel = 'stylesheet';
    document.head.appendChild(moyasarCss);

    moyasarScript.onload = () => {
      if (window.Moyasar) {
        window.Moyasar.init({
          element: '.mysr-form',
          amount: parseFloat(amount * 100),
          currency: 'SAR',
          description: 'test',
          publishable_api_key: 'pk_test_GvuFVt6MoXoYta6drR5dYakXRHP1veSFPqjFEPBh',
          callback_url: `https://alromansiah-new.bdaiat.com/payment-check-mayser`,
          methods: ['creditcard'],
          fixed_width: false,
          on_initiating: () => {
            return new Promise(async (resolve, reject) => {
              try {
                await onSubmit();
                // If onSubmit resolves, proceed with payment initiation
                resolve(true);
              } catch (error) {
                // If onSubmit rejects or throws an error, cancel payment initiation
                reject(error);
              }
            });
          },
        });

        // Attach any other event listeners after the form is initialized
        if (formRef.current) {
          formRef.current.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevent default form submission
          });
        }
      }
    };

    return () => {
      document.body.removeChild(moyasarScript);
      document.head.removeChild(moyasarCss);
    };
  }, [amount, onSubmit]);

  return (
    <section
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="mysr-form" ref={formRef}></div>
    </section>
  );
};

export default MoyasarPaymentForm;
